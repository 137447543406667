import { SubscriptionSeat, type LensPlatformClient } from "lens-platform-sdk";

export interface SubscriptionSeatWithBillingPeriod extends SubscriptionSeat {
  billingPeriod: string | null;
}

// The personal and team plan names are configured in Keycloak, and is not a plan in Recurly
// https://k8slens.recurly.com/plans
export const LensDesktopPersonalLicenseType = {
  personal: "personal",
} as const;
export const LensDesktopTeamLicenseType = {
  team: "team",
} as const;

// "Lens Desktop Pro" are plan names in Recurly, plus LensDesktopPersonalLicenseType, and LensDesktopTeamLicenseType
// https://k8slens.recurly.com/plans
export const LensDesktopLicenseType = {
  ...LensDesktopPersonalLicenseType,
  ...LensDesktopTeamLicenseType,
  proTrial: "Pro Trial",
  pro: "Lens Desktop Pro",
  proProd: "Pro",
  enterprise: "Lens Desktop Enterprise",
} as const;

export const LicenseType = LensDesktopLicenseType;

export interface DecodedAccessToken
  extends Exclude<Awaited<ReturnType<LensPlatformClient["getDecodedAccessToken"]>>, undefined> {
  license_type?: (typeof LicenseType)[keyof typeof LicenseType];
  license_exp?: number;
  license_iat?: number;
  license_trial?: boolean;
  license_features?: string[];
}

export type License = {
  type: DecodedAccessToken["license_type"];
  trial: boolean;
  features: DecodedAccessToken["license_features"];
  expiration: Date;
  issuedAt: Date;
};
