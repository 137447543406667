import { PropsWithChildren, useMemo } from "react";
import clsx from "clsx";

import styles from "./SkeletonText.module.css";

type SkeletonTextProps = PropsWithChildren<{
  loading: boolean;
  width?: string;
  height?: string;
  className?: string | ((loading: boolean) => string);
}>;

const SkeletonText = ({ loading, width, height, className, children }: SkeletonTextProps) => {
  const style = useMemo(() => {
    if (loading) {
      return {};
    }
    const styles: { width?: string; height?: string } = {};

    if (width) {
      styles.width = width;
    }

    if (height) {
      styles.height = height;
    }

    return styles;
  }, [width, height, loading]);

  return (
    <span
      className={clsx(typeof className === "function" ? className(loading) : className, {
        [styles.skeletonText]: loading,
      })}
      aria-live="polite"
      aria-busy={loading}
      style={style}
    >
      {!loading && children ? children : null}
    </span>
  );
};

export default SkeletonText;
