import { useMemo, useState } from "react";
import clsx from "clsx";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Invoice } from "lens-platform-sdk/dist/cjs/UserService";
import { action } from "@k8slens/lds-icons";

import { getPlanName, isLensTrialPlan } from "src/services/plans";
import { openUrl } from "src/utils/url";
import { getAccountInvoicePDFUrl } from "src/services/billing/billingUrl";
import { formatCurrency } from "src/utils/currency";
import { ChildBusiness } from "src/hooks/useBusinessChildren";

import { ContextMenuItem } from "src/components/ContextMenu/ContextMenu";
import Table from "src/components/Table/Table";
import { dateColDef } from "src/components/TableCells/DateCell";
import ContextMenuCell, { contextMenuColDef } from "src/components/TableCells/ContextMenuCell";

import styles from "./BillingDetails.module.css";

const { DownloadIcon } = action;
const invoiceColumnHelper = createColumnHelper<Invoice>();

export interface InvoicesProps {
  invoices: Invoice[];
  loading: boolean;
  billingPageToken: string | null;
  businessChildren?: ChildBusiness[];
}

export const Invoices = ({
  invoices: invoicesList,
  loading,
  billingPageToken: token,
  businessChildren,
}: InvoicesProps) => {
  const [invoicesSorting, setInvoicesSorting] = useState<SortingState>([]);

  // Filter out trial plan empty invoices.
  const invoices = useMemo(() => invoicesList.filter((invoice) => !isLensTrialPlan(invoice.planCode)), [invoicesList]);

  const downloadPDF = (invoiceNumber: string | null, token: string | null) => {
    if (!invoiceNumber || !token) {
      return;
    }
    const invoiceUrl = getAccountInvoicePDFUrl(invoiceNumber, token);

    if (!invoiceUrl) {
      return;
    }

    openUrl(invoiceUrl, true);
  };

  /**
   * Check if there are any invoices that are from a child business
   */
  const someChildrenInvoices = useMemo(() => {
    return invoices.some((invoice) => {
      if (typeof invoice.fromChildBusinessId === "string") {
        return true;
      }

      return false;
    });
  }, [invoices]);

  const invoicesColumns = useMemo(() => {
    const commonColumns = [
      invoiceColumnHelper.accessor("date", {
        ...dateColDef,
        meta: { ...dateColDef.meta, primary: true },
      }),
      invoiceColumnHelper.accessor("number", {
        header: () => <span>Invoice Number</span>,
        cell: ({ getValue }) => <div className={styles.invoiceId}>{getValue()}</div>,
      }),
      invoiceColumnHelper.accessor("planCode", {
        header: () => <span className="invoicePlan">Plan</span>,
        cell: ({ getValue }) => <div className="invoicePlanValue">{getPlanName(getValue())}</div>,
      }),
      invoiceColumnHelper.accessor("total", {
        header: () => <span>Amount</span>,
        cell: ({ row }) => {
          const invoice = row.original;

          return <div>{formatCurrency(invoice.total)}</div>;
        },
      }),
      invoiceColumnHelper.accessor("state", {
        header: () => <span>Status</span>,
        cell: ({ getValue }) => {
          const value = getValue() || "";

          return <div className={clsx(styles.status, styles[value] ?? styles.defaultState)}>{value}</div>;
        },
      }),
    ];
    const contextMenuColumn = invoiceColumnHelper.accessor("number", {
      ...contextMenuColDef,
      id: "actions",
      cell: ({ getValue }) => (
        <ContextMenuCell toggleButtonProps={{ title: "Toggle billing context menu" }}>
          <ContextMenuItem label="Download PDF" icon={DownloadIcon} onClick={() => downloadPDF(getValue(), token)} />
        </ContextMenuCell>
      ),
    });

    if (someChildrenInvoices) {
      const fromChildBusinessIdColumn = invoiceColumnHelper.accessor("fromChildBusinessId", {
        header: () => <span>From Child Business</span>,
        cell: ({ getValue }) => {
          const fromChildBusinessId = getValue();
          const childBusinessName = businessChildren?.find((child) => child.id === fromChildBusinessId)?.name;

          return <span>{childBusinessName ?? ""}</span>;
        },
      });

      return [...commonColumns, fromChildBusinessIdColumn, contextMenuColumn];
    }

    return [...commonColumns, contextMenuColumn];
  }, [token, someChildrenInvoices, businessChildren]);

  const invoicesTable = useReactTable({
    data: invoices,
    columns: invoicesColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setInvoicesSorting,
    state: {
      sorting: invoicesSorting,
    },
  });
  const invoicesTableRowModel = invoicesTable.getRowModel();
  const invoicesTableRows = useMemo(() => invoicesTableRowModel.rows, [invoicesTableRowModel]);

  return (
    <Table<Invoice>
      caption="Invoices"
      rows={invoicesTableRows}
      columns={invoicesTable.getAllColumns()}
      headerGroups={invoicesTable.getHeaderGroups()}
      loading={loading}
      noDataText="No invoices"
      headerGroupProps={{
        cellClassName: styles.tableHeader,
      }}
      rowProps={{
        cellClassName: styles.tableCell,
      }}
    />
  );
};
