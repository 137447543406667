import { useCallback, useEffect, useMemo, useState, type PropsWithChildren } from "react";
import clsx from "clsx";
import { type ColumnMeta } from "@tanstack/react-table";
import { Checkbox } from "@k8slens/lds";
import { type CheckboxProps } from "@k8slens/lds/lib/es/Checkbox/Checkbox";

import styles from "./CheckboxCell.module.css";

type CheckBoxCellProps = PropsWithChildren<{
  checkboxProps: CheckboxProps;
  isHeader?: boolean;
  className?: string;
}>;

const CheckboxCell = ({
  checkboxProps: { value, ...checkboxProps },
  isHeader,
  className,
  children,
}: CheckBoxCellProps) => {
  return (
    <span className={clsx(styles.checkbox, className, { [styles.header]: isHeader })}>
      <Checkbox value={Boolean(value)} {...checkboxProps} />
      {children}
    </span>
  );
};

export const checkboxCellMeta: ColumnMeta<unknown, unknown> | undefined = {
  cellClassName: styles.checkboxCellContainer,
};

export const checkboxColDef = {
  id: "checkbox",
  enableSorting: false,
  meta: checkboxCellMeta,
};

export const useCheckboxCells = (rows: Array<{ id: string }>) => {
  const [allChecked, setAllChecked] = useState(false);
  const [checkIndividually, setCheckIndividually] = useState<Record<string, boolean>>({});

  const checkedArr = useMemo(
    () =>
      Object.entries(checkIndividually)
        .filter(([, value]) => value)
        .map(([key]) => key),
    [checkIndividually],
  );

  useEffect(() => {
    const allChecked = Boolean(rows.length) && rows.every((row) => checkIndividually[row.id]);

    setAllChecked(allChecked);
  }, [checkIndividually, rows, setAllChecked]);

  const onCheckOne = useCallback(
    (id: string) => {
      setCheckIndividually((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    },
    [setCheckIndividually],
  );

  const unCheckAll = useCallback(() => {
    setCheckIndividually({});
  }, [setCheckIndividually]);

  const checkAll = useCallback(() => {
    const newCheckIndividually = (rows || []).reduce(
      (acc, row) => {
        acc[row.id] = true;

        return acc;
      },
      {} as Record<string, boolean>,
    );

    setCheckIndividually(newCheckIndividually);
  }, [setCheckIndividually, rows]);

  const onCheckAll = useCallback(() => {
    if (allChecked) {
      unCheckAll();
    } else {
      checkAll();
    }
  }, [allChecked, unCheckAll, checkAll]);

  const isChecked = useCallback((id: string) => checkIndividually[id] ?? false, [checkIndividually]);

  return {
    checkedArr,
    isChecked,
    onCheckOne,
    allChecked,
    onCheckAll,
    unCheckAll,
    checkAll,
    checkAllDisabled: !rows.length,
  };
};

export default CheckboxCell;
