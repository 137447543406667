import RouterLinkButton from "src/components/Button/TrackedRouterLinkButton";

import { useBusinessIdParam } from "src/hooks/useBusinessIdParam";

import styles from "./Catalog.module.css";
import { useBooleanFlagValue } from "@openfeature/react-sdk";

export function Catalog() {
  const businessId = useBusinessIdParam();
  const domainMatchingSettingsFlag = useBooleanFlagValue("show-domain-matching-settings", false);

  return (
    <div>
      <h3 className="lds-h4">Manage Business ID</h3>
      <div className={styles.catalogBlocks}>
        <section className={styles.block}>
          <p>View and edit company profile information including company name, logo and other contact details.</p>
          <RouterLinkButton to={`/business/${businessId}/profile`} size="sm" label="Edit Profile" />
        </section>
        {domainMatchingSettingsFlag ? (
          <section className={styles.block}>
            <p>
              Simplify user onboarding with domain matching. Manage domain settings, enable automatic join, and
              configure a custom messages to guide new users.
            </p>
            <RouterLinkButton to={`/business/${businessId}/domain-matching`} size="sm" label="Domain Matching" />
          </section>
        ) : null}
        <section className={styles.block}>
          <p>
            Configure how your users authenticate within the system. Set up Single Sign-On (SSO) and SCIM provisioning.
          </p>
          <RouterLinkButton to={`/business/${businessId}/auth`} size="sm" label="Edit Authentication" />
        </section>
      </div>
      <h3 className="lds-h4">Manage Subscriptions and Licenses</h3>
      <div className={styles.catalogBlocks}>
        <section className={styles.block}>
          <p>
            Manage and view billing information, invoices, subscriptions, payment methods and adjust subscriptions seat
            quantities.
          </p>
          <RouterLinkButton to={`/business/${businessId}/billing-details`} size="sm" label="Manage Billing" />
        </section>
        <section className={styles.block}>
          <p>Invite new users to your Business ID, assign them subscriptions, and manage their access roles.</p>
          <RouterLinkButton to={`/business/${businessId}/users`} size="sm" label="Manage Users" />
        </section>
        <section className={styles.block}>
          <p>
            Centralized feature management allows you to enable or disable features across all users within your Lens
            Business ID.
          </p>
          <RouterLinkButton to={`/business/${businessId}/feature-flag-settings`} size="sm" label="Manage Features" />
        </section>
      </div>
    </div>
  );
}
