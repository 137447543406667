import { type PropsWithChildren } from "react";
import { type ColumnMeta } from "@tanstack/react-table";
import { navigation } from "@k8slens/lds-icons";

import { ContextMenu, type ContextMenuProps } from "src/components/ContextMenu/ContextMenu";

import styles from "./ContextMenuCell.module.css";

const { ContextMenuIcon } = navigation;

const ContextMenuCell = ({ children, ...props }: PropsWithChildren<ContextMenuProps>) => (
  <div className={styles.menuContainer}>
    <ContextMenu toggleIcon={ContextMenuIcon} {...props}>
      {children}
    </ContextMenu>
  </div>
);

export const contextMenuCellMeta: ColumnMeta<unknown, unknown> | undefined = {
  align: "start",
  cellClassName: styles.contextMenuContainer,
};

export const contextMenuColDef = {
  meta: contextMenuCellMeta,
  enableSorting: false,
  id: "context-menu",
  header: () => <span />,
  cell: ContextMenuCell,
};

export default ContextMenuCell;
