import { LoadingIndicator } from "@k8slens/lds";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "src/hooks/useAuth";
import { useCanDownloadCode } from "src/hooks/useCanDownloadCode";

export function LoginViewOfflineCode() {
  const history = useHistory();
  const { authenticated, isNavigating } = useAuth();
  const canDownload = useCanDownloadCode();

  useEffect(() => {
    if (!isNavigating && authenticated) {
      if (!canDownload) {
        // redirect immediately if the user cannot download the offline activation code
        // we need to use `window.location.replace` to re-init the auth provider to ensure
        // the app is authenticated using `keycloakClientId` instead of `keycloakLensDesktopClientId`
        window.location.replace(`${window.location.origin}/`);
      }

      if (canDownload) {
        history.push("/offline-activation-code/view");
      }
    }
  }, [authenticated, canDownload, history, isNavigating]);

  return <LoadingIndicator size="2xl" />;
}
