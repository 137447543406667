import { ActiveLensSubscription } from "./ActiveLensSubscription";
import { planMetadata } from "src/services/plans";
import { PersonalBilling } from "./PersonalBilling";

export function TeamSubscription() {
  return (
    <ActiveLensSubscription
      licenseType="team"
      features={planMetadata.personal.features}
      billing={<PersonalBilling />}
    />
  );
}
