import { useContext } from "react";
import { SSOType } from "lens-platform-sdk";

import { getACSUrl, getSamlSSOEntityID } from "src/services/sso";

import { BusinessContext } from "src/components/Business/Base";
import { CopyToClipboard } from "src/components/CopyToClipboard/CopyToClipboard";

import styles from "./BusinessSSOUrl.module.css";

export const BusinessSSOUrl = ({ ssoType }: { ssoType: SSOType }) => {
  const { businessId } = useContext(BusinessContext);
  const acsUrl = getACSUrl(businessId);
  const acsForIdpInitiatedLoginUrl = `${acsUrl}/clients/idp-initiated-sso`;
  const acsUrlLabel = ssoType === SSOType.OIDC ? "Redirect URI" : "ACS URL";
  const entityID = getSamlSSOEntityID(businessId);

  return (
    <dl className={styles.ssoInfo}>
      <dt title={ssoType === SSOType.OIDC ? "" : "Assertion Consumer Service URL"}>
        <strong>{`${acsUrlLabel}:`}</strong>
      </dt>
      <dd data-testid="acsUrl">
        <span className={styles.ssoInfoText} title={acsUrl}>
          {acsUrl}
        </span>
        {acsUrl ? <CopyToClipboard text={acsUrl} /> : null}
      </dd>
      {ssoType === SSOType.SAML ? (
        <>
          <dt title="Assertion Consumer Service URL for IdP Initiated Logins">
            <strong>{"ACS URL for IdP Initiated Logins"}</strong>
          </dt>
          <dd>
            <span className={styles.ssoInfoText}>{acsForIdpInitiatedLoginUrl}</span>
            {entityID ? <CopyToClipboard text={acsForIdpInitiatedLoginUrl} /> : null}
          </dd>
          <dt aria-label="Service Provider Entity ID">
            <strong>Service Provider Entity ID:</strong>
          </dt>
          <dd data-testid="entityID">
            <span className={styles.ssoInfoText} title={entityID}>
              {entityID}
            </span>
            {entityID ? <CopyToClipboard text={entityID} /> : null}
          </dd>
        </>
      ) : null}
    </dl>
  );
};
