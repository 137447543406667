import { LoadingIndicator } from "@k8slens/lds";
import { useEffect } from "react";
import Page from "src/components/Page/Page";
import { useCanDownloadCode } from "src/hooks/useCanDownloadCode";
import { useViewActivationCode } from "src/hooks/useViewActivationCode";

export function ViewCode() {
  const { viewActivationCode, viewed, offlineActivationCode, warning } = useViewActivationCode();
  const canDownload = useCanDownloadCode();

  useEffect(() => {
    if (offlineActivationCode && !viewed && canDownload) {
      viewActivationCode();
    }

    if (viewed) {
      // redirect immediately if has downloaded the offline activation code
      // we need to use `window.location.replace` to re-init the auth provider to ensure
      // the app is authenticated using `keycloakClientId` instead of `keycloakLensDesktopClientId`
      window.location.replace(`${window.location.origin}/`);
    }
  }, [viewActivationCode, offlineActivationCode, viewed, canDownload]);

  if (warning) {
    return (
      <Page>
        <div className="flex flex-col items-center justify-center min-h-screen">
          <h1 className="text-center">{warning}</h1>
        </div>
      </Page>
    );
  }

  return <LoadingIndicator size="2xl" />;
}
