import React from "react";
import clsx from "clsx";
import { Menu, Transition } from "@headlessui/react";
import { Button } from "@k8slens/lds";
import { navigation } from "@k8slens/lds-icons";
import { type Icon } from "@k8slens/lds-icons/lib/cjs/Icon/Icon.d";

import styles from "./ContextMenu.module.css";

const { MenuIcon } = navigation;

interface ContextMenuItemProps {
  label: string;
  icon?: Icon;
  onClick: Function;
  disabled?: boolean;
  title?: string;
  buttonType?: "button" | "submit" | "reset";
  buttonDiscreet?: boolean;
  buttonClassName?: string;
}

export interface ContextMenuProps {
  children?: React.ReactNode;
  disabled?: boolean;
  toggleIcon?: Icon;
  toggleButtonProps?: Partial<React.ButtonHTMLAttributes<HTMLButtonElement>>;
}

export const ContextMenu = ({ disabled, children, toggleIcon: Icn, toggleButtonProps }: ContextMenuProps) => (
  <div className={styles.invitationsMenu}>
    <div className={styles.menuContainer}>
      <Menu>
        <Menu.Button // eslint-disable-next-line react/jsx-props-no-spreading
          disabled={disabled}
          {...toggleButtonProps}
          className={clsx(styles.menuButton, toggleButtonProps?.className)}
        >
          {Icn ? <Icn /> : <MenuIcon />}
        </Menu.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Menu.Items className={styles.menu}>{children}</Menu.Items>
        </Transition>
      </Menu>
    </div>
  </div>
);

export const ContextMenuItem = ({
  label,
  icon,
  onClick,
  disabled,
  buttonType,
  buttonDiscreet,
  buttonClassName,
}: ContextMenuItemProps) => (
  <div className={styles.menuItem}>
    <Menu.Item>
      <Button
        disabled={disabled}
        label={label}
        aria-label={label}
        icon={icon}
        onClick={() => onClick()}
        buttonType={buttonType}
        discreet={buttonDiscreet}
        className={buttonClassName}
      />
    </Menu.Item>
  </div>
);
