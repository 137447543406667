import { EvaluationContext } from "@openfeature/react-sdk";

/**
 * "on" for staging, development, and test environments, "off" for production
 */
export const onStagingOrDevOrTest = (context: EvaluationContext) => {
  if (context.isStaging) {
    return "on";
  }

  if (context.isDevelopment || context.isTest) {
    return "on";
  }

  return "off";
};

export const allOn = (_: EvaluationContext) => {
  return "on";
};

export const flagConfig = {
  "scim-token-ui": {
    disabled: false,
    variants: {
      on: true,
      off: false,
    },
    defaultVariant: "off",
    contextEvaluator: allOn,
  },
  "auto-join-ui": {
    disabled: false,
    variants: {
      on: true,
      off: false,
    },
    defaultVariant: "off",
    contextEvaluator: allOn,
  },
  "show-create-lbid-button": {
    disabled: false,
    variants: {
      on: true,
      off: false,
    },
    defaultVariant: "off",
    contextEvaluator: (context: EvaluationContext) => {
      if (context.isDevelopment) {
        return "on";
      }

      return "off";
    },
  },
  "show-domain-matching-settings": {
    disabled: false,
    variants: {
      on: true,
      off: false,
    },
    defaultVariant: "off",
    contextEvaluator: (context: EvaluationContext) => {
      if (context.isTest || context.isDevelopment || context.isStaging) {
        return "on";
      }

      return "off";
    },
  },
  "lens-team-license": {
    disabled: false,
    variants: {
      on: true,
      off: false,
    },
    defaultVariant: "off",
    contextEvaluator: (context: EvaluationContext) => {
      if (context.isTest || context.isDevelopment || context.isStaging) {
        return "on";
      }

      return "off";
    },
  },
};
