import { isAssignableSubscription } from "src/services/getSubscriptionState";
import { useGetBusinessSubscriptions } from "./useGetBusinessSubscriptions";
import { getSubscriptionSeatCount } from "src/services/getSubscriptionSeatCount";

export const useSubscriptionSeatCount = (businessId: string) => {
  const {
    businessSubscriptions,
    loading: loadingBusinessSubscriptions,
    error,
  } = useGetBusinessSubscriptions(businessId);

  const assignableBusinessSubscriptions = (businessSubscriptions || []).filter(isAssignableSubscription);

  const seatCount = getSubscriptionSeatCount(assignableBusinessSubscriptions);

  return {
    seatCount,
    loading: loadingBusinessSubscriptions,
    error,
  };
};
