import { useMemo } from "react";

import { getPlanData, type PlanCode } from "src/services/plans";

import { useGetBusinessSubscriptions } from "./useGetBusinessSubscriptions";
import { useBooleanFlagValue } from "@openfeature/react-sdk";

export const useGetCurrentBusinessPlan = (businessId?: string) => {
  const { businessSubscriptions, loading, reload } = useGetBusinessSubscriptions(businessId);

  const lensTeamLicenseFlag = useBooleanFlagValue("lens-team-license", false);

  const subscription = useMemo(() => {
    if (loading || !businessSubscriptions) {
      return undefined;
    }

    return businessSubscriptions
      .filter((sub) => sub.state === "active")
      .sort((a, b) => {
        if (!a.currentPeriodStartedAt) {
          return 1;
        }

        if (!b.currentPeriodStartedAt) {
          return -1;
        }

        return new Date(b.currentPeriodStartedAt).getTime() - new Date(a.currentPeriodStartedAt).getTime();
      })[0];
  }, [businessSubscriptions, loading]);

  const plan = useMemo(() => {
    if (loading) {
      return undefined;
    }

    if (subscription) {
      return getPlanData(subscription.planCode as PlanCode);
    }

    if (lensTeamLicenseFlag) {
      return getPlanData("team" as PlanCode);
    }

    return null;
  }, [loading, subscription, lensTeamLicenseFlag]);

  return {
    subscription,
    plan,
    loading,
    reload,
  };
};
