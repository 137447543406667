import { Header, SortDirection } from "@tanstack/react-table";
import clsx from "clsx";
import { ReactNode } from "react";
import { navigation } from "@k8slens/lds-icons";

import styles from "./Table.module.css";

const { ArrowDownIcon, ArrowUpIcon } = navigation;

type IconProps = {
  isSorted: false | SortDirection;
  canSorted: boolean;
};

const SortIcon = ({ isSorted, canSorted }: IconProps) => {
  if (canSorted === false) {
    return null;
  }

  if (isSorted === false) {
    return <ArrowDownIcon className={clsx(styles.sortIcon, styles.inactive)} />;
  }

  if (isSorted === "asc") {
    return <ArrowDownIcon className={styles.sortIcon} />;
  }

  return <ArrowUpIcon className={styles.sortIcon} />;
};

type Props<TData> = {
  header: Header<TData, unknown>;
  cellClassName: string;
  renderHeaderCell(header: Header<TData, unknown>): ReactNode;
};

export const TableHeader = function <T>({ header, cellClassName, renderHeaderCell }: Props<T>) {
  const getCanSort = header.column.getCanSort();
  const isSorted = header.column.getIsSorted();
  const { meta } = header.column.columnDef;
  const sortable = header.column.getCanSort();

  return (
    <th
      scope="col"
      key={header.id}
      className={clsx(styles.tableHeader, cellClassName, meta?.cellClassName, {
        [styles.sortable]: sortable,
        [styles.center]: meta?.align === "center",
        [styles.end]: meta?.align === "end",
      })}
      onClick={header.column.getToggleSortingHandler()}
    >
      <span>
        {header.isPlaceholder ? null : renderHeaderCell(header)}
        <SortIcon isSorted={isSorted} canSorted={getCanSort} />
      </span>
    </th>
  );
};
