import {
  LensSDKException,
  MultiStatusException,
  type BusinessJoinRequestWithCreatedBy,
  type BusinessJoinRequest,
} from "lens-platform-sdk";
import { useMutation, useQuery } from "@tanstack/react-query";

import { queryClient } from "src/queryClient";
import { useLensPlatformClient } from "src/hooks/useLensPlatformClient";
import { useAnalytics } from "src/hooks/useAnalytics";

/**
 * Hooks for Business Join Requests
 */
export const useBusinessJoinRequest = (businessId?: string, state?: BusinessJoinRequest["state"]) => {
  const lensPlatformClient = useLensPlatformClient();
  const { track } = useAnalytics();

  const {
    isLoading: loading,
    error,
    data: requests,
    refetch: getRequests,
  } = useQuery<BusinessJoinRequestWithCreatedBy[], LensSDKException>({
    queryKey: ["businessJoinRequests", businessId, state],
    queryFn: async () => {
      if (!businessId) {
        return [];
      }
      const data = await lensPlatformClient.business.getBusinessJoinRequests(businessId);

      if (state) {
        return data.filter((request) => request.state === state);
      }

      return data;
    },
    enabled: Boolean(businessId),
    initialData: [],
  });

  const { error: errorUpdatingRequest, mutateAsync: updateRequestAsync } = useMutation<
    BusinessJoinRequest,
    LensSDKException,
    {
      id: Parameters<typeof lensPlatformClient.business.updateBusinessJoinRequest>[1];
      state: Parameters<typeof lensPlatformClient.business.updateBusinessJoinRequest>[2];
    }
  >({
    mutationFn: async ({ id, state }) => {
      if (!businessId) {
        throw new Error("Invalid businessId");
      }

      return lensPlatformClient.business.updateBusinessJoinRequest(businessId, id, state);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["businessJoinRequests", businessId] });
      track("Business Join Request Updated", { businessId });
    },
  });

  const { error: errorUpdatingRequests, mutateAsync: updateRequestsAsync } = useMutation<
    Array<Pick<BusinessJoinRequest, "id" | "state">>,
    LensSDKException,
    Array<Pick<BusinessJoinRequest, "id" | "state">>
  >({
    mutationFn: async (data) => {
      if (!businessId) {
        throw new Error("Invalid businessId");
      }

      return lensPlatformClient.business.updateBusinessJoinRequests(businessId, data);
    },

    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["businessJoinRequests", businessId] });
      track("Business Join Requests Updated", { businessId });
    },

    onError: async (error) => {
      if (error instanceof MultiStatusException) {
        await queryClient.invalidateQueries({ queryKey: ["businessJoinRequests", businessId] });
        track("Business Join Requests Updated", { businessId });
      }
    },
  });

  return {
    // GET
    loading,
    error,
    requests,
    count: requests.length,
    getRequests,
    // UPDATE (one)
    updateRequestAsync,
    errorUpdatingRequest,
    // UPDATE (many)
    updateRequestsAsync,
    errorUpdatingRequests,
  };
};
