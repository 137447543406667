import { useBusiness } from "./useBusiness";

export const useIsDomainMatchingOn = (businessId: string) => {
  const { business, loadingBusiness, errorLoadingBusiness } = useBusiness(businessId);

  return {
    isDomainMatchingOn: Boolean(business?.emailDomainMatchingEnabled),
    loading: loadingBusiness,
    error: errorLoadingBusiness,
  };
};
