import { useMemo, useState } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { type Icon } from "@k8slens/lds-icons/lib/es/Icon/Icon";
import { idp, action } from "@k8slens/lds-icons";
import { LinkedUserAccount } from "lens-platform-sdk";

import { AvatarTableCell } from "src/components/TableCells/AvatarTableCell";
import Table from "src/components/Table/Table";

import styles from "./page.module.css";

const { GithubIcon, GoogleIcon } = idp;
const columnHelper = createColumnHelper<LinkedUserAccount>();

const { DeleteIcon } = action;

export type Props = {
  accounts: LinkedUserAccount[];
  onDeleteAccount: (alias: string) => void;
  loading: boolean;
};

export const ConnectedAccountsTable = ({ accounts, onDeleteAccount, loading }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const columns = useMemo(
    () => [
      columnHelper.accessor("username", {
        header: () => <span>Account</span>,
        cell: ({ row }) => {
          const { username, identityProviderAlias, identityProviderDisplayName } = row.original;

          let image: Icon | null = null;

          switch (identityProviderAlias) {
            case "google":
              image = GoogleIcon;
              break;
            case "github":
              image = GithubIcon;
              break;
            default:
              image = null;
          }

          return (
            <AvatarTableCell
              image={image}
              name={username ?? ""}
              nameWithAtSign={false}
              subtitle={identityProviderDisplayName || identityProviderAlias}
            />
          );
        },
        meta: { primary: true },
      }),
      columnHelper.accessor("identityProviderAlias", {
        header: () => <span />,
        enableSorting: false,
        cell: ({ getValue }) => {
          return (
            <div className={styles.deleteLinkedAccount}>
              <button
                type="button"
                data-testid="unlinkAccount"
                onClick={() => onDeleteAccount(getValue()!)}
                aria-label="Unlink account"
              >
                <DeleteIcon />
              </button>
            </div>
          );
        },
      }),
    ],
    [onDeleteAccount],
  );

  const table = useReactTable({
    data: accounts,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });
  const tableRowModel = table.getRowModel();
  const tableRows = useMemo(() => tableRowModel.rows, [tableRowModel]);

  return (
    <Table<LinkedUserAccount>
      showCaption={false}
      caption=""
      responsive={false}
      rows={tableRows}
      loading={loading}
      columns={table.getAllColumns()}
      headerGroups={table.getHeaderGroups()}
      noDataText="No linked accounts"
    />
  );
};
