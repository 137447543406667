import { Modal } from "@k8slens/lds";
import TrackedButton from "src/components/Button/TrackedButton";

interface Props {
  isOpened: boolean;
  onCancel: () => void;
  onDownload: () => void;
}

export const ViewOfflineActivationCodeModal = ({ onDownload, isOpened, onCancel }: Props) => (
  <Modal
    title="View Activation Code"
    size="md"
    onClose={onCancel}
    isOpen={isOpened}
    footer={
      <>
        <TrackedButton label="Cancel" id="cancel-view" onClick={onCancel} />
        <TrackedButton label="View" id="View" onClick={onDownload} primary />
      </>
    }
  >
    <p>
      The Activation Code is meant for using Lens Desktop in environments where online validation for Lens Subscriptions
      is not available. By downloading the Activation Code, the associated Lens Subscription will be fixed to you and no
      changes will be possible until the end of the subscription term.
    </p>
  </Modal>
);
